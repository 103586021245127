<div class="alert-overlay" *ngIf="visible">
    <div class="alert-box">
      <span class="alert-close" (click)="closeAlert()">×</span>
      <h2 class="alert-title">{{ title }}</h2>
      <a></a>
      <p *ngIf="isTrackInfo" class="alert-message"> Enter Tracking Id:</p>
      <input *ngIf="isTrackInfo" id="tid" [(ngModel)]="tid" name="tid" required>
      <br>
      <br>
      <button *ngIf="isTrackInfo && !trackClicked" class="alert-ok" (click)="track()">Track</button>
      <img height="40px" width="40px" *ngIf="isLoading" src="/assets/images/loading.gif" alt="Loading..." />
      <p *ngIf="isTrackInfo && trackClicked && !isLoading" class="alert-message">Transaction status for your
        package is: {{ trackingStatus }}</p>
      <p *ngIf="!isTrackInfo" class="alert-message" (click)="openWhatsapp()">Call or chat at <a [href]="linkUrl" target="_blank">9740585405</a> instant for booking of your package</p>
      <button *ngIf="!isTrackInfo || trackClicked" class="alert-ok" (click)="okay()">Okay</button>
    </div>
  </div>
  