import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TrackingService } from './tracking.service';
import { DeliveryTrackingService } from '../services/delivery-tracking.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent {
  @Input() title: string = 'Alert';
  @Input() message: string = 'This is an alert message.';
  @Input() isTrackInfo: boolean = false
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  trackingStatus: string | null = null;
  isLoading: boolean = false;

  tid: string = '';
  trackClicked = false
  constructor(private trackingService: TrackingService, private delhiveryTrackingService: DeliveryTrackingService) {}
  private readonly whatsappNumber: string = '9740585405';
  private readonly whatsappMessage: string = 'Hello, I would like to make an inquiry!';
  visible: boolean = true;


  fetchTrackingData(){
    this.isLoading = true;

    // Replace with the appropriate request body
    const requestBody = {
      "UserID":"UWX03",
      "Password":"UWX03",
      "AWBNo": this.tid
  };

    this.trackingService.postTrackingData(requestBody).subscribe(
      data => {
        this.isLoading = false;
        if (data.Response && data.Response.Tracking.length > 0) {
          this.trackingStatus = data.Response.Tracking[0].Status;
        } else {
          this.trackingStatus = 'In Transit';
        }
      },
      error => {
        this.isLoading = false;
        console.error('Error fetching tracking data', error);
        this.trackingStatus = 'In Transit';
      }
    );
  }



  fetchDelhiveryTracking() {
    if (!this.tid) {
      alert('Please enter a waybill number');
      return;
    }
    console.log("called ");
    this.delhiveryTrackingService.getTrackingData(this.tid).subscribe(
      (data) => {
        this.trackingStatus = data[0].Shipment.Status.Status;
        console.log(this.trackingStatus);
      },
      (error) => {
        this.trackingStatus = 'In Transit';
        console.error('Error fetching shipment data', error);
        alert('Failed to fetch shipment data. Please try again.');
      }
    );
  }


  closeAlert() {
    this.visible = false;
    this.onClose.emit();
  }
  openWhatsapp(){
    const encodedMessage = encodeURIComponent(this.whatsappMessage);
    const url = `https://wa.me/${this.whatsappNumber}?text=${encodedMessage}`;
    window.open(url, '_blank');
  }
  track(){
    this.trackClicked = true
    if(this.tid.startsWith('922')|| this.tid.startsWith('555')){
      this.fetchTrackingData();
    }else if(this.tid.startsWith('47')){

    }
  }

  okay() {
    this.visible = false;
    this.onClose.emit();
  }
}
/*
<app-alert 
  *ngIf="showAlert" 
  [title]="'Alert Title'" 
  [message]="'This is a custom alert message!'"
  (onClose)="handleAlertClose()">
</app-alert>
<button (click)="showAlert = true">Show Alert</button>
*/

/*
showAlert: boolean = false;

  handleAlertClose() {
    this.showAlert = false;
  }
    */

